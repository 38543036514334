button {
  font-size: 12px;
}

button:active {
  opacity: 50%;
}

:focus {
  outline: 0;
}

body {
  font-family: sans-serif;
}

html {
  touch-action: manipulation;
}

a:link,
a:visited {
  color: #ed9f5d;
}

a:active,
a:hover {
  color: darkorange;
}

@media screen and (-webkit-min-device-pixel-ratio:0){
    body {
        padding: 0;
        margin: 0;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    _::-webkit-full-page-media, _:future, :root body {
        padding: 0;
        margin: 0;
    }
}